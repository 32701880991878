<template v-if="item">
  <div class="p-d-flex p-ai-center p-flex-wrap">
    <div @click="toggleOrderStateOverlay($event, item.id)" class="state-badge pointer" :class="OrderStates.find(state => state.num === item.state)?.color || ''">{{ OrderStates.find(state => state.num === item.state)?.label[$i18n.locale] || '' }}</div>
    <div v-if="canChangeState">
<!--      <a v-if="item.state === 1" @click.prevent="changeOrderState(3)" v-tooltip.top="'Is waiting'" href="#" class="p-link table-link-icon p-d-flex p-ai-center">-->
      <a v-if="item.state === 1" @click.prevent="changeOrderStateToWaitingOnClick" v-tooltip.top="'Is waiting'" href="#" class="p-link table-link-icon p-d-flex p-ai-center"><i class="ti-arrow-circle-right"></i></a>
<!--      <a v-if="item.state === 1" @click.prevent="changeOrderState(item, 2, item.state)" v-tooltip.top="'Change state to received'" href="#" class="p-link table-link-icon"><i class="ti-arrow-circle-right"></i></a>-->
      <a v-else-if="item.state === 3" @click.prevent="changeOrderState(5)" v-tooltip.top="'In work'" href="#" class="p-link table-link-icon p-d-flex p-ai-center"><i class="ti-arrow-circle-right"></i></a>
<!--      <a v-else-if="item.state === 2" @click.prevent="changeOrderState(item, 5, item.state)" v-tooltip.top="'Change state to In work'" href="#" class="p-link table-link-icon"><i class="ti-arrow-circle-right"></i></a>-->
      <a v-else-if="item.state === 5" @click.prevent="changeOrderState(7)" v-tooltip.top="'Completed'" href="#" class="p-link table-link-icon p-d-flex p-ai-center"><i class="ti-arrow-circle-right"></i></a>
      <a v-else-if="item.state === 7" @click.prevent="changeOrderState(8)" v-tooltip.top="'Car issued'" href="#" class="p-link table-link-icon p-d-flex p-ai-center"><i class="ti-arrow-circle-right"></i></a>
    </div>
  </div>
  <OverlayPanel :ref="'os-' + item.id" :breakpoints="{'960px': '75vw'}">
    <div v-if="item.state_history && item.state_history.length">
      <div v-for="(data, index) of [...item.state_history].sort((a, b) => b.id - a.id)" :key="index">
        <div class="p-d-flex p-ai-center p-jc-between">
<!--          <div class="p-d-flex p-ai-center">-->
          <div>
            <div class="state-badge" :class="data.new ? OrderStates.find(state => state.num === data.new)?.color : ''">{{ data.new ? OrderStates.find(state => state.num === data.new)?.label[$i18n.locale] : '-' }}</div>
          </div>
          <div class="p-ml-3">
            <span v-if="data.created_at">{{ formatDate(data.created_at) }}, </span>
            <span v-if="data.creator">
              <span v-if="data.creator.type === constants.userTypes.legal_entity && data.creator.company_name">{{ data.creator.company_name }}</span>
              <span v-else>{{ data.creator.first_name }} {{ data.creator.last_name }}</span>
            </span>
          </div>
        </div>
        <br v-if="index !== item.state_history.length - 1">
      </div>
    </div>
    <div v-else>{{ $t('No data available' )}}</div>
  </OverlayPanel>
</template>

<script>
// import OverflowOverlayPanel from '@/components/OverflowOverlayPanel'
import OrderStates from '@/translations/states/OrderStates'
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import constants from "@/constants";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import webSocketMixins from "@/mixins/webSocketMixins";

export default {
  // settings,
  mixins: [ showErrorsMixins, formatMixins, webSocketMixins ],
  // components: { OverflowOverlayPanel },
  emits: ['update-item', 'change-order-state-to-waiting-on-click'],
  name: 'OrderStateButton',
  props: {
    item: Object,
    canChangeState: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false,
      constants,
      OrderStates
    }
  },
  methods: {
    changeOrderStateToWaitingOnClick() {
      const obj = {
        id: this.item.id,
        staffComment: this.item.staff_comment
      }
      this.$emit('change-order-state-to-waiting-on-click', obj)
    },
    async changeOrderState(newState, changeStaffComment = false, staffComment) {
      if (this.isLoading || !this.item) return
      const order = this.item
      const oldState = this.item.state
      order.state = newState
      this.isLoading = true

      try {
        const { status, data } = await httpClient.post('order/change-state', {orderId: order.id, state: newState, changeStaffComment, staffComment})
        if (status === 200 && data?.success) {
          // const oldState = order.state
          // order.state = newState
          // this.updatedItemId = order.id
          // order.state = data.state

          const orderStateHistoryIds = []

          if (order.state_history?.length) {
            order.state_history.forEach(h => orderStateHistoryIds.push(+h.id))
          }
          const maxOrderStateHistoryId = Math.max(...orderStateHistoryIds)

          order.state_history.push({
            id: maxOrderStateHistoryId + 1,
            old: oldState,
            new: newState,
            created_at: Date.now() / 1000,
            creator: {
              first_name: this.$store.state.user.first_name,
              last_name: this.$store.state.user.last_name,
            }
          })
          // this.$emit('change-order-state', order.id, true
          if (oldState === 1 && newState !== oldState) {
            this.$emit('update-item', order.id, false)
          }
          this.sendUpdateOrderWebSocket(order.id)
        } else if (data?.error) {
          order.state = oldState
          console.log(data.error)
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        }
      } catch(err) {
        order.state = oldState
        this.showError(err)
      } finally {
        this.isLoading = false
      }
    },
    toggleOrderStateOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['os-' + id.toString()].toggle(event);
    },
  }
}
</script>